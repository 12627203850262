import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../layouts/layout';
import IndexSlider from '../components/IndexSlider/index';

export default props => {
  const storyblok = props.data.allStoryblokEntry.edges.map(({node}) => {
    const {_uid, cover, customDate} = JSON.parse(node.content);
    return {
      date: customDate ? new Date(customDate) : new Date(node.first_published_at),
      key: _uid,
      slug: node.slug,
      cover,
      title: node.name,
    };
  });

  const sortedPosts = storyblok.sort((a, b) => b.date - a.date);

  return (
    <Layout>
      <IndexSlider url={props.data.site.siteMetadata.siteUrl} posts={sortedPosts} />
    </Layout>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allStoryblokEntry(filter: {slug: {ne: "about"}}) {
      edges {
        node {
          name
          first_published_at
          slug
          content
          parent_id
        }
      }
    }
  }
`;
